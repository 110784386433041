import { getPageByUrl } from '@lib/cms-client';
import { GetServerSidePropsContext } from 'next';
import Layout from '@components/layout';
import { Menu, Page, Settings } from '@lib/types';
import Sections from '@components/sections';
import { Seo } from '@components/seo';
import { Tracking } from '@components/tracking';

type GeneralPageProps = {
  isPreview: boolean;
  menu: Menu;
  menuFooter: Menu;
  menuFooterSecondary: Menu;
  page: Page;
  settings: Settings;
  url: string;
};

export default function GeneralPage({
  isPreview,
  page: { sections, seo, title },
  menu,
  menuFooter,
  menuFooterSecondary,
  settings,
  url,
}: GeneralPageProps) {
  return (
    <>
      <Seo title={title} seo={seo} url={url} />
      {settings.googleAnalytics.trackingId && (
        <Tracking trackingId={settings.googleAnalytics.trackingId} />
      )}
      <Layout
        isPreview={isPreview}
        menu={menu}
        menuFooter={menuFooter}
        menuFooterSecondary={menuFooterSecondary}
        settings={settings}>
        <Sections key={url} sections={sections} settings={settings} />
      </Layout>
    </>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const host = context.req.headers.host;
  const baseUrl = `http${host?.includes('localhost') ? '' : 's'}://${host}`;
  const path =
    context.params && context.params.slug && Array.isArray(context.params.slug)
      ? `/${context.params.slug.join('/')}`
      : context.params && context.params.slug
      ? `/${context.params.slug}`
      : '/';
  const url = new URL(path, baseUrl).toString();

  const page = await getPageByUrl(path, context.locale, context.preview);

  if (!page) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      page,
      isPreview: context.preview ?? false,
      url,
    },
  };
}
